.get-started-form {
  display: flex;
  max-width: 400px;
  width: 100%;
  position: relative;
  fieldset {
    width: 100%;
    .form-group {
      height: 52px;
      width: 100%;
      input {
        width: 100%;
        background: #fff;
        margin: 0;
        border: 1px solid #aaa;
        border-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
        height: 100%;
        padding: 0 1rem;
      }
    }
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  button {
    border: none;
    padding: 0 20px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #21a9e1;
    border-color: #21a9e1;
    margin-bottom: 0;
    color: #fff;
    font-weight: 500;
  }
}
