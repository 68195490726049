@import "../../../../../../styles/variables.scss";

.swiper-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 25;
  background-color: rgba(black, 0.5);
  justify-content: center;
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 50%;
    height: auto;
    max-height: 90%;
    // .swiper-modal {
    //   width: 100%;
    //   aspect-ratio: 1;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   .swiper-wrapper {
    //     // display: flex;
    //     // align-items: center;
    //     // justify-content: center;
    //   }
    //   .modal-slide {
    //     justify-content: center;
    //     align-self: center;
    //     background-color: black;
    //     .slide-overlay {
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //       height: 100%;
    //       width: 100%;
    //       background-image: linear-gradient(rgba(black, 0) 70%, black);
    //     }
    //   }
    //   .swiper-button-next,
    //   .swiper-button-prev {
    //     background-color: white;
    //     width: 30px;
    //     height: 30px;
    //     border-radius: 50%;
    //   }
    //   .swiper-button-next:after,
    //   .swiper-button-prev:after {
    //     font-size: 12px;
    //     color: black;
    //     font-weight: bold;
    //   }
    // }
    @include tablet {
      width: 70%;
    }
    @include mobile {
      width: 90%;
    }
  }
}
