@import "../../styles/variables.scss";

.nav-container {
  position: relative;
  z-index: 20;
  padding: 0 56px;
  width: 100%;

  @include mobile() {
    padding: 0 24px;
  }
  .nav-desktop {
    justify-content: space-between;
    height: 85px;
    width: 100%;
    .left-nav {
      justify-content: flex-start;
      gap: 3rem;
      @include tablet {
        gap: 1.5rem;
      }
      .logo {
        width: 150px;
      }
      .list-items {
        flex-grow: 1;
        justify-content: space-between;
        max-width: 300px;
        gap: 1rem;
        li {
          cursor: pointer;
          > span {
            font-weight: 500;
            font-size: 14px;
          }
        }
        @include mobile {
          display: none;
        }
      }
    }
    .right-nav {
      flex-grow: 1;
      justify-content: flex-end;
      gap: 1rem;

      @include mobile {
        display: none;
      }
    }
    .right-nav-mobile {
      display: none;
      background-color: transparent;
      border: none;
      flex-wrap: nowrap;
      @include mobile {
        display: block;
      }
    }
    @include tablet() {
      .left-nav {
        gap: 1.5rem;
        span {
          font-size: 12px;
        }
      }
      .right-nav {
      }
    }
  }
}

.profile-content {
  width: 80px;
  height: 40px;
  justify-content: space-between;
  padding: 4px;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 100px;
  cursor: pointer;
  .avatar {
    height: 100%;
    aspect-ratio: 1;
    background-color: #979797;
    border-radius: 100%;
    overflow: hidden;
    justify-content: center;
  }
  > svg {
    margin-right: 10px;
  }
}

.signin-btn {
  background-color: black;
  width: max-content;
  .text {
    color: white;
  }
}

.account-drawer {
  right: 24px;
  top: 110%;
  max-height: 50vh;
  min-width: 300px;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  z-index: 120;
  .noti-item,
  .account-item {
    cursor: pointer;
    &:hover {
      background-color: rgba(#2f80ed, 0.05);
    }
  }
}

.noti-drawer,
.account-drawer,
.mess-drawer {
  right: 24px;
  top: 100%;
  max-height: 50vh;
  min-width: 300px;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  z-index: 1;
  .noti-item,
  .account-item {
    cursor: pointer;
    &:hover {
      background-color: rgba(#2f80ed, 0.05);
    }
  }
}
.badge-icon {
  cursor: pointer;
  width: 26px;
}
