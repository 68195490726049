@import "../../styles/variables.scss";

.section-9 {
  padding-top: 150px;
  padding-bottom: 30px;
  gap: 2rem;
  position: relative;
  overflow: hidden;
  max-width: none;
  * {
    z-index: 10;
  }
  h1 {
    width: 100%;
    max-width: 700px;
    font-size: 48px;
  }
  h3 {
    width: 100%;
    max-width: 700px;
    font-weight: 300;
  }
  .bg-1,
  .bg-2 {
    position: absolute;
    filter: blur(50px);
    opacity: 0.5;
    height: 100%;
  }
  .bg-1 {
    left: -30%;
    top: -10%;
  }
  .bg-2 {
    right: -30%;
    top: -10%;
  }
  @include tablet() {
    .bg-1,
    .bg-2 {
      top: 10%;
      opacity: 0.7;
    }
    .bg-1 {
      left: -60%;
    }
    .bg-2 {
      right: -70%;
    }
    h1 {
      font-size: 40px;
    }
  }
  @include mobile() {
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin: 0;
    h1 {
      font-size: 32px;
      line-height: 1.2;
    }
    h3 {
      font-size: 16px;
      width: 70%;
    }
    .button {
      width: 231px;
      padding: 0 24px;
      font-size: 16px;
    }
    .bg-1,
    .bg-2 {
      top: 10%;
      opacity: 0.7;
    }
    .bg-1 {
      left: -60%;
    }
    .bg-2 {
      right: -70%;
    }
  }
  .coming-soon-social {
    margin-top: 2rem;
    gap: 1rem;
    .coming-soon-social-circle {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #eff5f9;
      img {
        height: 20px;
        width: auto;
      }
    }
  }
  .coming-soon-copyright {
    color: var(--dark-grey);
    opacity: 0.5;
    font-size: 13px;
    margin-top: 2rem;
  }
}
