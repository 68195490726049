@import "../../../styles/variables.scss";

.nav-mobile-container {
  position: fixed;
  transition: var(--transition-all);
  display: none;
  @include mobile() {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100vh;
    width: max-content;
    padding: 0 24px 2rem 2rem;
    padding-top: 1.7rem;
    right: 0;
    top: 0;
    background-color: var(--white);
    z-index: 30;
    box-shadow: -8px 60px 20px rgba(0, 0, 0, 0.1);
    gap: 2rem;
    .nav-mobile-menu {
      width: 24px;
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      justify-content: flex-start;
      gap: 1rem;
      height: 100%;
      li {
        width: 100%;
      }
      .logout-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: end;
        button {
          padding: 0;
          color: red;
          background-color: transparent;
        }
        flex-grow: 1;
      }
    }
  }
}
