@import "./styles/variables.scss";

:root {
  /* Colors */
  --base-primary-1: #264d6b;
  --base-primary-2: #21a9e1;
  --base-second-1: #f7f7f7;
  --base-second-2: #ebf3fa;
  --success: #219653;
  --error: #eb5757;
  --info: #00a2ff;
  --warning: #f8eb46;
  --dark-gray: #333333;
  --black: #000000;
  --white: #ffffff;
  --btn-blue: #2675ec;
  --bg-blue: #f5f9ff;
  --swiper-theme-color: #616161;
  --swiper-navigation-size: 18px;
  --btn-radius: 8px;
  --transition-all: all 0.2s ease-in;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
* {
  box-sizing: border-box;
}

.font-muslish {
  font-family: "Mulish", sans-serif;
}
.lora-font {
  font-family: "Lora", serif;
}
html {
  width: 100vw;
  overflow-x: hidden;
  height: auto;
}
h1 {
  position: relative;
  font-size: 32px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: -1px;
}
h2 {
  position: relative;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: -1px;
}
h3 {
  position: relative;
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: -0.5px;
}
h4 {
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: -0.5px;
}
a {
  color: var(--black);
}
img {
  width: 100%;
}

/* Custom class */
.section-text {
  font-size: 56px;
  line-height: 1.2;
  letter-spacing: -3px;
  font-weight: 700;
}
.text-blue {
  color: var(--btn-blue);
}
.body-text {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}
.caption {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.link {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.body-light {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
  letter-spacing: -0.5px;
}
.body-reg {
  font-size: 18px;
  line-height: 1.5;
  font-weight: normal;
  letter-spacing: -0.5px;
}
.body-semibold {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -1px;
}
.body-bold {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: -0.5px;
}
.text-s {
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  letter-spacing: -0.5px;
}
.text-s-semibold {
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 0;
}
.text-xs-semibold {
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: -0.5px;
}
.text-xs-bold {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
}
.flex-horizontal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flex-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button {
  font-family: "Mulish", sans-serif;
  padding: 0 32px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  outline: none;
  border-radius: var(--btn-radius);
  border: none;
  opacity: 1;
  transition: var(--transition-all);
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}
.button:hover {
  opacity: 0.8;
}
.btn-blue {
  background-color: var(--btn-blue);
}
.btn-black {
  background-color: var(--black);
}
.btn-outline {
  background-color: transparent;
  border: solid 1px var(--black);
  color: var(--black);
}
.bg {
  position: absolute;
  z-index: 1 !important;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  filter: blur(100px);
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}

body {
  background-color: var(--white);
  color: var(--black);
  font-family: "Mulish", sans-serif;
}
section {
  padding: 0 56px;
  @include mobile() {
    padding: 0 24px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(#f1f1f1, 0.4);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(#888, 0.4);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(#888, 1);
}

.disable-link {
  pointer-events: none;
  color: #616161;
}
