@import "../../../../src/styles/variables.scss";

.setting-container {
  max-width: 1028px;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
  padding-top: 50px;
  gap: 1rem;
  .setting-content {
    flex-grow: 1;
    background: #f7f7f7;
    border: 1px solid #e2e3e4;
    border-radius: 8px;
    overflow: hidden;
    .content-item {
      .edit-btn {
        font-family: "Mulish";
        background-color: transparent;
        border: none;
        color: #2675ec;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.5px;
      }
      .del-btn {
        font-family: "Mulish";
        background: #ffdfe0;
        border: 1px solid #ff3a44;
        border-radius: 6px;
        color: red;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.5px;
        padding: 12px 16px;
      }
    }
  }
}
